var storeFinder = {
    lat: null,
    long: null,
    currentLocation: "Current Location",

    setCurrentLocation: function (lat, long) {
        storeFinder.lat = lat;
        storeFinder.long = long;
    },

    submitForm: function (params, method, path) {
        var form = document.createElement("form");
        form.method = method;
        form.action = path;

        for (var key in params) {
            if (params.hasOwnProperty(key)) {
                var hiddenField = document.createElement("input");
                hiddenField.type = "hidden";
                hiddenField.name = key;
                hiddenField.value = params[key];
                form.appendChild(hiddenField);
            }
        }
        document.body.appendChild(form);
        form.submit();
        return false;
    },

    search: function () {
        if (typeof grecaptcha === "object") {
            grecaptcha.execute();
        } else {
            storeFinder.searchRecaptchaCallback();
        }
    },

    searchRecaptchaCallback: function (recaptchaToken) {
        var params = {};
        var countryInfo = storeFinder.getCountryInformation();

        params["location"] = storeFinder.getLocation();
        params["countryCode"] = countryInfo.countryCode;
        params["countryName"] = countryInfo.countryName;
        params["radius"] = storeFinder.getSearchRadius();

        if (recaptchaToken) {
            params["g-recaptcha-response"] = recaptchaToken;
        }

        if (storeFinder.lat && storeFinder.long) {
            params["lat"] = storeFinder.lat;
            params["long"] = storeFinder.long;
        }

        storeFinder.submitForm(params, "post", storeSearchServiceUrl);
    },

    getSearchRadius: function () {
        return $("#storeFinderRadius").val();
    },

    getCountryInformation: function () {
        return {
            countryCode: $("#storeFinderCountryDropDown").val(),
            countryName: $.trim(jQuery("#storeFinderCountryDropDown option:selected").text()),
        };
    },

    getLocation: function () {
        if (storeFinder.lat && storeFinder.long) return storeFinder.currentLocation;
        return $.trim($("#storeFinderSearchLocation").val());
    },

    requestCurrentLocationFromClient: function () {
        storeFinder.setCurrentLocation(null, null);

        if (enableGeoLocationStoreFinder && navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                function (position) {
                    storeFinder.searchUsingLocation(position.coords.latitude, position.coords.longitude);
                },
                function (error) {
                    switch (error.code) {
                        case error.TIMEOUT:
                            $("#locationTimeout").show();
                            break;
                        case error.POSITION_UNAVAILABLE:
                            $("#locationUnavailable").show();
                            break;
                        case error.PERMISSION_DENIED:
                            $("#locationDenied").show();
                            break;
                        case error.UNKNOWN_ERROR:
                            $("#locationUnavailable").show();
                            break;
                        default:
                            $("#locationUnavailable").show();
                    }
                    return false;
                },
            );
        }
    },

    searchUsingLocation: function (lat, long) {
        storeFinder.setCurrentLocation(lat, long);
        storeFinder.search();
    },

    init: function () {
        var $storeFinderLocationInput = $("#storeFinderSearchLocation");
        $storeFinderLocationInput.focus();
        $storeFinderLocationInput.keydown(function (e) {
            if (e.keyCode == 13) {
                storeFinder.search();
            }
        });

        $("#storeFinderSubmit").click(function (e) {
            e.preventDefault();
            storeFinder.search();
        });

        var $storeFinderSearchGPS = $("#storeFinderSearchGPS");
        if ($storeFinderSearchGPS.length > 0)
            $storeFinderSearchGPS.click(function () {
                storeFinder.requestCurrentLocationFromClient();
                return false;
            });

        if (enableWoosMapLocalitiesWidget && typeof woosmap !== "undefined" && woosmap)
            storeFinderWoosmapAutocomplete.configure();

        if (!navigator.geolocation || !enableGeoLocationStoreFinder) {
            $("#plGeolocation").hide();
        }
    },
};

var storeFinderWoosmapAutocomplete = {
    widget: undefined,

    configure: function () {
        var countryInfo = storeFinder.getCountryInformation();

        if (countryInfo.countryCode) {
            storeFinderWoosmapAutocomplete.widget = new woosmap.localities.Autocomplete("storeFinderSearchLocation", {
                components: { country: countryInfo.countryCode },
                data: "advanced",
                minLength: 3,
            });

            storeFinderWoosmapAutocomplete.widget.addListener("selected_locality", function () {
                var prediction = storeFinderWoosmapAutocomplete.widget.getSelectedLocality();
                if (prediction) {
                    $("#storeFinderSearchLocation").val(prediction.description);

                    if (prediction.location)
                        storeFinder.searchUsingLocation(prediction.location.lat, prediction.location.lng);
                    else storeFinder.search();
                }
            });
        }

        storeFinderWoosmapAutocomplete.addListener.onCountryChange();
    },

    addListener: {
        onCountryChange: function () {
            $("#storeFinderCountryDropDown").on("change", function () {
                if (
                    typeof storeFinderWoosmapAutocomplete.widget !== "undefined" &&
                    storeFinderWoosmapAutocomplete.widget
                ) {
                    var countryInfo = storeFinder.getCountryInformation();
                    if (countryInfo.countryCode) {
                        storeFinderWoosmapAutocomplete.widget.components.country = countryInfo.countryCode;
                    }

                    var prevResults = storeFinderWoosmapAutocomplete.widget.resultsContainer.children;
                    while (prevResults.length > 0) {
                        prevResults[0].remove();
                    }
                }
            });
        },
    },
};

function recaptchaCallBack(token) {
    storeFinder.searchRecaptchaCallback(token);
}

$(document).ready(function () {
    storeFinder.init();
});
